<!-- 寫作測驗題目及答案 -->
<template>
  <div class="TopicWritingAnswer">
    <!-- 題型title -->
    <h1 class="TopicWritingAnswer-h1">
      {{ dataForm.group_title }}. 寫作（{{ partTitle }}）
      <!-- {{ topicData.progressNum || topicList[0].progressNum }}. 寫作（{{ partTitle }}） -->
    </h1>
    <div class="TopicWritingAnswer-box">
      <!-- 題目-STA -->
      <!-- 题目类型 文 -->
      <div class="box-left topic1" ref="topicRef1" v-if="topicType == 1">
        <div class="topic1-box" v-html="topicData.content"></div>
      </div>
      <!-- 题目类型 图 -->
      <div class="box-left topic2" ref="topicRef2" v-if="topicType == 2">
        <div class="topic2-box">
          <img :src="topicData.image_uri" alt="">
        </div>
      </div>
      <!-- 题目类型 文+图 -->
      <div class="box-left topic1" ref="topicRef3" v-if="topicType == 4">
        <div class="topic1-box">
          <div v-html="topicData.content"></div>
          <img :src="topicData.image_uri" alt="">
        </div>
      </div>
      <!-- 題目-END -->

      <!-- 答案-STA -->
      <div class="box-right-div">
        <!-- 答案类型 文本不限字数 -->
        <div class="box-right answer1" v-if="dataForm.group_type == 1 && answerType == 2">
          <textarea spellcheck='false' v-model="topicData.answer.answer" @blur="answerChange(1)" placeholder="請輸入完整的句子。"></textarea>
        </div>
        <!-- 答案类型 题组文本不限字数 -->
        <div class="box-right answer2" ref="answerRef" v-if="dataForm.group_type == 2">
          <div class="box-right-input" v-for="(item, index) in topicList" :key="index">
            <span :class="active == index ? 'active' : ''">{{ index+1 }}</span>
            <input type="text" spellcheck='false' v-model="item.answer.answer" @focus="inputFocus(index)" @blur="answerChange(2)" placeholder="請輸入答案">
          </div>
        </div>
        <!-- 答案类型 文本限制50字元 -->
        <div class="box-right answer3" v-if="dataForm.group_type == 1 && answerType == 3">
          <div class="box-right-title">
            <img src="@/assets/image/icon/icon-edit.png" alt="">
            <p>
              字數：<span>{{ textLength }}</span><!--/50-->
            </p>
          </div>
          <div class="box-right-bg">
            <img src="@/assets/image/examination/writing2.png" alt="">
          </div>
          <div class="box-right-input">
            <textarea spellcheck='false' v-model="topicData.answer.answer" @input="textChange" @blur="answerChange(3)" placeholder="請完成一篇約50字的短文。"></textarea>
          </div>
        </div>
        <!-- 遮罩層 -->
        <!-- <div class="right-mask" v-if="dataForm.group_type == 2"></div> -->
      </div>
      <!-- 答案-END -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopicWritingAnswer',
  props: {
    partTitle: {
      type: String,
      default: ''
    },
    dataForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      active: null,
      dataList: [
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        }
      ],
      
      textLength: 0,
      textValue: '',

      // 题目数据
      topicData: {},
      // 题组数据
      topicList: [],
      // 题目类型
      topicType: 0,
      // 答案类型
      answerType: 0,
    }
  },
  watch: {
    dataForm: {
      handler() {
        // 初始化的時候根據判斷渲染數據
        if (this.dataForm.group_type == 1) { // 1單體形式  2題組形式
          const data = this.dataForm.question?.[0]
          // 數據賦值
          this.topicData = data
          // 題目類型賦值
          this.topicType = data.show_type
          // 答案類型賦值
          this.answerType = data.answer_type
          // 答案賦值
          this.answerCheck = data.answer.answer

          if (data.answer_type == 3) {
            this.textChange()
          }
        } else {
          // 數據賦值
          this.topicList = this.dataForm.question
          // 數據賦值
          this.topicData = this.dataForm
          // 題目類型賦值
          this.topicType = this.dataForm.show_type
        }
      },
      deep: true,
      immediate: true
    },
    // 'topicData.answer.answer'(newVal, oldVal) {
    //   // 使判斷連續輸入換行
    //   if (/\n{2,}/.test(newVal)) {
    //     const cleanedValue = newVal.replace(/\n{2,}/g, '\n');
    //     this.topicData.answer.answer = cleanedValue;
    //   }

    //   // 判斷換行超過10個不讓換行
    //   const nLength = newVal.split('\n').length
    //   if (nLength > 11) {
    //     this.topicData.answer.answer = oldVal;
    //   }

    //   // 判斷換行超過10個不讓換行
    //   let rLength = newVal.split(' ').length
    //   if (rLength > 255) {
    //     this.topicData.answer.answer = oldVal;
    //   }
    // }
  },
  methods: {
    // input获取焦点事件
    inputFocus(index) {
      this.active = index
    },
    // 文字长度变化
    textChange() {
      let val = this.topicData.answer.answer || ''
      // 将前后空格不计算为单词数
      val = val.replace(/^\s+|\s+$/gi,"")
      // 多个空格替换成一个空格
      val = val.replace(/\s+/gi," ")
      val = val.replace(/\n|\r/gi," ")
	    let match = val.match(/\s/g);
      if (val) {
        if (match) {
          this.textLength = match.length + 1
        } else {
          this.textLength = 1
        }
      } else {
        this.textLength = 0
      }
    },
    
    // 頁面滾動到頂部
    toRoll() {
      // 初始化的时候答案區塊滚动条置顶
      if (this.$refs.answerRef) this.$refs.answerRef.scrollTop = 0
      // 初始化的时候問題區塊滚动条置顶
      if (this.$refs.topicRef1) this.$refs.topicRef1.scrollTop = 0
      if (this.$refs.topicRef2) this.$refs.topicRef2.scrollTop = 0
      if (this.$refs.topicRef3) this.$refs.topicRef3.scrollTop = 0
    },

    // 文本框内容输入
    answerChange(type) {
      if (type == 1) { // 文本不限制字数
        this.$emit('playChange', {type: 1, data: this.topicData})
      } else if (type == 2) { // 题组 文本不限制字数
        this.active = null
        this.$emit('playChange', {type: 2, data: this.topicList})
      } else { // 文本限制50字元
        this.$emit('playChange', {type: 1, data: this.topicData})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.TopicWritingAnswer {
  width: 100%;
  height: 100%;
  .TopicWritingAnswer-h1 {
    padding-bottom: 10px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #333333;
  }
  .TopicWritingAnswer-box {
    width: 100%;
    height: calc(100% - 45px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-left {
      width: calc(50% - 10px);
      height: 100%;
      overflow: auto;
    }
    .box-right {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .box-right-div {
      padding-bottom: 10px;
      width: calc(50% - 10px);
      height: calc(100% - 10px);
      position: relative;
      .right-mask {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        border-radius: 0px 0px 0px 4px;
        pointer-events: none;
      }
    }
    .topic1 {
      width: calc(50% - 38px);
      height: calc(100% - 28px);
      background: #F7F8FB;
      padding: 14px;
      .topic1-box {
        width: 100%;
        height: 100%;
        overflow: auto;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        color: #333333;
        white-space: pre-line;
        border-radius: 4px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
    .topic2 {
      width: calc(50% - 10px);
      height: 100%;
      .topic2-box {
        width: 100%;
        height: 100%;
        overflow: auto;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        color: #333333;
        white-space: pre-line;
        border-radius: 4px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
    .answer1 {
      overflow: hidden;
      background: #F9FAFC;
      textarea {
        border-radius: 4px;
        padding: 20px;
        width: calc(100% - 42px);
        height: calc(100% - 42px);
        word-break: break-word;
        border: none;
        background: #F9FAFC;
        border: 1px solid #F9FAFC;
        resize: none;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        color: #0C4FA2;
      }
      textarea::-webkit-input-placeholder {
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        color: #989898;
        opacity: 0.5;
      }
      textarea:focus{
        border: 1px solid #3774E4;
      }
    }
    .answer2 {
      overflow: auto;
      border-radius: 4px;
      .box-right-input {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin-right: 10px;
          width: 48px;
          height: 48px;
          background: #EEEEEE;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 48px;
          text-align: center;
          color: #333333;
        }
        .active {
          color: #FFFFFF;
          background: #0C4FA2;
        }
        input {
          padding: 0 20px;
          width: 520px;
          height: 70px;
          background: #FFFFFF;
          border: 1px solid #EAEEF4;
          border-radius: 8px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #0C4FA2;
        }
        input::-webkit-input-placeholder {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #989898;
          opacity: 0.5;
        }
        input:focus {
          border: 1px solid #3774E4;
        }
      }
    }
    .answer3 {
      position: relative;
      .box-right-title {
        z-index: 2;
        position: absolute;
        top: 6px;
        left: 43px;
        height: 24px;
        display: flex;
        align-items: center;
        img {
          margin-right: 6px;
          width: 16px;
          height: 16px;
        }
        p {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #FFFFFF;
          span {
            font-weight: 500;
          }
        }
      }
      .box-right-bg {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
      }
      .box-right-input {
        z-index: 2;
        position: absolute;
        top: 38px;
        right: 20px;
        width: 560px;
        height: 432px;
        overflow: hidden;
        textarea {
          border-radius: 4px;
          padding: 10px;
          width: calc(100% - 22px);
          height: calc(100% - 22px);
          word-break: break-word;
          border: none;
          background: #F9FAFC;
          border: 1px solid #F9FAFC;
          resize: none;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #0C4FA2;
        }
        textarea::-webkit-input-placeholder {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: rgba(152, 152, 152, 0.5);
        }
        textarea:focus{
          border: 1px solid #3774E4;
        }
      }
    }
  }
}
</style>